import React from "react";
import Logo from "../../images/Logo";
import LetterLogo from "../../images/LetterLogo";
import MenuImage from "../../images/Menu.png";
import MenuBar from "./MenuBar";

function Header({ toggleMenu, scrollToTop, isMenuVisible }) {
  const setMenuVisible = (value) => {
    if (!value && isMenuVisible) {
      toggleMenu();
    }
  };

  return (
    <>
      <div className="w-full h-[55px] left-0 top-0 fixed z-50 bg-black/80">
        <div className="w-6 h-6 left-[156px] top-[15px] absolute" />
        <div className="w-[194.25px] h-[55px] pl-6 pr-[20.25px] pt-[18px] pb-[19px] left-0 top-0 absolute justify-center items-center inline-flex">
          <div className="w-[150px] self-stretch justify-center items-start gap-[6.66px] inline-flex">
            <div
              className="w-[125.15px] h-[18px] relative flex items-center cursor-pointer"
              onClick={scrollToTop}
            >
              <div>
                <Logo />
              </div>
              <div className="mx-2">
                <LetterLogo />
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-6 h-6 right-[34px] top-[15px] absolute cursor-pointer"
          onClick={toggleMenu}
        >
          <img
            src={MenuImage}
            alt="Menu"
            className="w-full h-full object-contain"
          />
        </div>
      </div>

      <div
        className={`fixed top-0 right-0 w-full h-full bg-black/50 flex justify-end z-50 transition-opacity duration-300 ${
          isMenuVisible ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setMenuVisible(false);
          }
        }}
      >
        <div
          className={`w-[208px] h-[770px] bg-black relative transform transition-transform duration-500 ease-out ${
            isMenuVisible ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="w-6 h-6 right-[34px] top-[15px] absolute cursor-pointer z-50"></div>
          <MenuBar
            isMenuVisible={isMenuVisible}
            setMenuVisible={setMenuVisible}
          />
        </div>
      </div>
    </>
  );
}

export default Header;
