import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PartnershipPageBackgroundImage from "../../images/PartnershipPageBackground.png";
import PartnershipPageImage2 from "../../images/PartnershipPageImage2.png";
import { useNavigate } from "react-router-dom";

function Partnership() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Force scroll to top when component mounts
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const goToHome = () => {
    navigate("/");
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <div className="w-full min-h-screen bg-black relative overflow-x-hidden">
      <Header
        toggleMenu={toggleMenu}
        scrollToTop={goToHome}
        isMenuVisible={isMenuVisible}
      />
      <div className="w-[1440px] h-[2410px] relative bg-black border border-black/20">
        <div className="w-[1440px] h-[770px] left-0 top-0 absolute justify-center items-center inline-flex">
          <div
            className="w-[1440px] h-[770px] bg-black/60"
            style={{
              backgroundImage: `url(${PartnershipPageBackgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </div>
        <div className="left-[194px] top-[500px] absolute text-white text-[80px] font-bold font-['PT Sans'] leading-[80px]">
          A new revolution in F&B
          <br />
          is taking place here.
        </div>
        <div className="left-[200px] top-[852px] absolute text-white text-4xl font-bold font-['PT Mono'] leading-[72px]">
          Partnership F&B Stores
        </div>
        <div className="w-[510px] left-[200px] top-[924px] absolute text-white/70 text-base font-normal font-['PT Sans'] leading-tight">
          Introducing various f&B spaces around the world that have joined the
          ecosystem of HMP. NFT holders from the HMP ecosystem can receive
          special benefits at all affiliates.
        </div>
        <div className="left-[730px] top-[1305px] absolute flex-col justify-start items-start gap-2.5 inline-flex">
          <a
            href="https://galvanized-radon-a17.notion.site/5e856988fc3448fd9ace808eca571694?v=21923ed00762415cb4fedb39e08a3c03"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative inline-block"
          >
            <div
              className="px-[30px] py-5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex
                bg-black transition-all duration-300 ease-in-out cursor-pointer
                group-hover:bg-[#ef5230]"
            >
              <div className="text-white text-xl font-normal font-['PT Sans'] leading-tight">
                View Our Partnership Restaurants
              </div>
            </div>
          </a>
        </div>
        <div className="left-[730px] top-[1390px] absolute flex-col justify-start items-start gap-2.5 inline-flex">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScAdcz8GOOk35GUfDMN2nZTLu5ulzOQpnFNb_pEoSnfd8Y3nQ/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative inline-block"
          >
            <div
              className="self-stretch px-[30px] py-5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex
              bg-black transition-all duration-300 ease-in-out cursor-pointer
              group-hover:bg-[#ef5230]"
            >
              <div className="text-white text-xl font-normal font-['PT Sans'] leading-tight">
                Contact for Partnership
              </div>
            </div>
          </a>
        </div>
        <div className="w-[510px] h-[413px] left-[200px] top-[1037px] absolute flex-col justify-start items-start inline-flex">
          <img
            className="w-[490px] h-[415px]"
            src={PartnershipPageImage2}
            alt="Partnership Page Image"
          />
        </div>
        <div className="w-[1440px] h-[55px] left-0 top-0 absolute">
          <div className="w-6 h-6 left-[156px] top-[15px] absolute" />
          <div className="w-[1440px] h-[55px] left-0 top-0 absolute bg-black/80" />
          <div className="w-[194.25px] h-[55px] pl-6 pr-[20.25px] pt-[18px] pb-[19px] left-0 top-0 absolute justify-center items-center inline-flex">
            <div className="w-[150px] self-stretch justify-center items-start gap-[6.66px] inline-flex">
              <div className="w-[125.15px] h-[18px] relative"></div>
            </div>
          </div>
          <div className="w-6 h-6 left-[1396px] top-[15px] absolute" />
        </div>
      </div>
      <div className="relative mt-[-6700px]">
        <Footer scrollToTop={goToHome} />
      </div>
    </div>
  );
}

export default Partnership;
