import React from "react";
import { useNavigate } from "react-router-dom";

import XIcon from "../../images/XIcon.png";
import InstagramIcon from "../../images/InstagramIcon.png";
import WhatsAppIcon from "../../images/WhatsAppIcon.png";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <footer className="w-full bg-black mt-20 pb-8">
      <div className="container mx-auto px-6">
        <div className="text-center text-[#ef5230] text-[28px] font-bold font-['PT Sans'] leading-9 italic mb-40">
          Bringing Web3 to Life,
          <br />
          One Map at a Time
        </div>
        {/* Navigation Links */}
        <div className="flex flex-col gap-5 mb-16">
          <div className="cursor-pointer" onClick={() => handleNavigation("/")}>
            <div className="text-white text-xl font-bold font-['PT Sans'] leading-tight">
              Home
            </div>
          </div>
          <div
            className="text-white text-xl font-bold font-['PT Sans'] leading-tight cursor-pointer"
            onClick={() => handleNavigation("/story")}
          >
            Story
          </div>
          <div
            className="text-white text-xl font-bold font-['PT Sans'] leading-tight cursor-pointer"
            onClick={() => handleNavigation("/team")}
          >
            Team
          </div>
          <div
            className="text-white text-xl font-bold font-['PT Sans'] leading-tight cursor-pointer"
            onClick={() => handleNavigation("/partnership")}
          >
            Partnership
          </div>
        </div>

        {/* Slogan */}

        {/* Social Links and Join Button Row */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-5">
            <a
              href="https://x.com/map3protocol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={XIcon}
                alt="X (Twitter)"
                className="w-8 h-8 opacity-80"
                loading="lazy"
                decoding="async"
              />
            </a>
            <a
              href="https://www.instagram.com/hidemeplease_official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="Instagram"
                className="w-8 h-8 opacity-80"
                loading="lazy"
                decoding="async"
              />
            </a>
            <img
              src={WhatsAppIcon}
              alt="WhatsApp"
              className="w-8 h-8 opacity-80"
              loading="lazy"
              decoding="async"
            />
          </div>

          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScAdcz8GOOk35GUfDMN2nZTLu5ulzOQpnFNb_pEoSnfd8Y3nQ/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="group"
          >
            <div className="px-2.5 py-2 rounded-[30px] border border-[#ef5230]">
              <div className="text-center text-white text-base font-bold font-['PT Sans'] leading-tight whitespace-nowrap">
                Join our Map
              </div>
            </div>
          </a>
        </div>

        {/* Email */}
        <div className="opacity-50 text-white text-xs font-normal font-['PT Sans'] leading-3 mb-4">
          help@hidemeplease.xyz
        </div>

        {/* Divider - now spans full viewport width */}
        <div className="relative w-screen left-[50%] right-[50%] -mx-[50vw]">
          <div className="w-full border border-[#ef5230]/50"></div>
        </div>

        {/* Copyright */}
        <div className="opacity-50 text-center text-[#ef5230] text-xs font-normal font-['PT Sans'] leading-[14px] mt-4">
          © 2024 map3protocol. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
