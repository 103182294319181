import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function MenuBar({ isMenuVisible, setMenuVisible }) {
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        isMenuVisible
      ) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuVisible, setMenuVisible]);

  return (
    <div ref={menuRef} className="w-[198px] h-[770px] relative bg-black">
      <div
        className={`left-[30px] top-[76px] absolute ${
          location.pathname === "/" ? "text-[#ef5230]" : "text-white"
        } text-[28px] font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/")}
      >
        Home
      </div>
      <div
        className={`left-[30px] top-[140px] absolute ${
          location.pathname === "/story" ? "text-[#ef5230]" : "text-white"
        } text-[28px] font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/story")}
      >
        Story
      </div>
      <div
        className={`left-[30px] top-[204px] absolute ${
          location.pathname === "/team" ? "text-[#ef5230]" : "text-white"
        } text-[28px] font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/team")}
      >
        Team
      </div>
      <div
        className={`left-[30px] top-[268px] absolute ${
          location.pathname === "/partnership" ? "text-[#ef5230]" : "text-white"
        } text-[28px] font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/partnership")}
      >
        Partnership
      </div>
    </div>
  );
}

export default MenuBar;
