import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function MenuBar({ isMenuVisible, setMenuVisible }) {
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        isMenuVisible
      ) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuVisible, setMenuVisible]);

  return (
    <div ref={menuRef} className="w-[150px] h-[800px] relative bg-black">
      <div className="w-[150px] h-[800px] left-0 top-0 absolute bg-black" />
      <div
        className={`left-[25px] top-[50px] absolute ${
          location.pathname === "/" ? "text-[#ef5230]" : "text-white"
        } text-xl font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/")}
      >
        Home
      </div>
      <div
        className={`left-[25px] top-[98px] absolute ${
          location.pathname === "/story" ? "text-[#ef5230]" : "text-white"
        } text-xl font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/story")}
      >
        Story
      </div>
      <div
        className={`left-[25px] top-[146px] absolute ${
          location.pathname === "/team" ? "text-[#ef5230]" : "text-white"
        } text-xl font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/team")}
      >
        Team
      </div>
      <div
        className={`left-[25px] top-[194px] absolute ${
          location.pathname === "/partnership" ? "text-[#ef5230]" : "text-white"
        } text-xl font-bold font-['PT Sans'] leading-7 cursor-pointer hover:opacity-80`}
        onClick={() => navigate("/partnership")}
      >
        Partnership
      </div>
    </div>
  );
}

export default MenuBar;
