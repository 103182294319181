import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import HMPEuljiroImage from "../../images/HMPEuljiro.png";
import HMPLetterLogoImage from "../../images/HMPLetterLogo.png";
import HMPBenefitImage from "../../images/HMPBenefit.png";
import PigTailImage from "../../images/PigTail.png";
import HyunYuImage from "../../images/HyunYu.png";

function Story() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Force scroll to top when component mounts
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const goToHome = () => {
    navigate("/");
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <>
      <div className="w-full min-h-screen bg-black relative overflow-x-hidden">
        <Header
          toggleMenu={toggleMenu}
          scrollToTop={goToHome}
          isMenuVisible={isMenuVisible}
        />
        <div className="w-full h-[1550px] relative bg-black mt-[30px] ">
          <div className="w-[260px] left-[22px] top-[133px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-9">
            Ixplorer's Story
          </div>
          <div className="w-[90%] mx-auto left-[22px] top-[226px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-1">
            I have successfully operated a variety of brands, including churros,
            Italian restaurants, pizza shops, bakeries, donut shops, and pubs.
            However, I found it challenging to scale the business further
            without relying on franchising. This led me to explore new and
            innovative ways to make the most out of these offline stores. One
            day, I noticed that all the customers in my café were playing
            Pokémon Go. At first, I thought they were simply having fun, but I
            soon discovered that my café was set as an in-game item shop
            (PokéStop) in Pokémon Go. As a result, a huge number of people
            started visiting, and sales skyrocketed. But what excited me even
            more was realizing the potential of linking games with offline
            stores.
          </div>
          <div className="left-[22px] top-[172px] absolute text-white text-xs font-normal font-['PT Sans'] leading-3">
            by Hyun Yu (Founder&CEO)
          </div>
          <div className="w-[84.39px] h-[51px] left-[64px] top-[82px] absolute">
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative left-7 top-8"
            >
              <path
                id="Vector 1"
                d="M1 22C1.37637 17.24 4.72608 8.22396 15.1139 10.24C28.0987 12.76 16.243 23.1201 11.4443 16.68C7.60531 11.528 16.0548 4.07999 20.7595 1"
                stroke="#EF5230"
              />
            </svg>
            <div className="left-[4.35px] top-0 absolute origin-top-left rotate-[12.57deg] text-[#ef5230] text-xs font-normal font-['PT Sans'] leading-tight whitespace-nowrap">
              CEO's Nickname
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-[90%] h-[336.55px] top-[466px] absolute">
              <img
                className="w-full h-[336.55px] left-0 top-0 absolute object-cover"
                src={HMPEuljiroImage}
                alt="HMP Euljiro"
              />
              <div className="w-[101.58px] h-[60.95px] left-[110.42px] top-[22.97px] absolute shadow">
                <div className="w-[99.69px] h-[55.34px] left-[0.94px] top-[2.36px] absolute">
                  <div className="w-[99.69px] h-[55.34px] left-0 top-0 absolute"></div>
                  <div className="w-[94.07px] h-[50.56px] left-[1.07px] top-[1.15px] absolute">
                    <div className="w-[69.82px] h-[24.30px] left-0 top-0 absolute"></div>
                    <div className="w-[65.84px] h-[33.46px] left-[17.80px] top-[17.11px] absolute"></div>
                    <div className="w-[13.57px] h-[14.46px] left-[80.49px] top-[2.60px] absolute"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="w-[85px] h-[85px] absolute rounded-[42.50px] right-[12px] top-[108px] object-cover"
            src={HyunYuImage}
            alt="Hyun Yu"
          />
          <div className="w-[90%] mx-auto left-[22px] top-[826px] absolute text-white/70 text-xs font-normal font-['PT Sans'] leading-1">
            To bring this vision to life, I saw Web3 technology, with its high
            degree of connectivity, as the answer. Imagine a system where
            leveling up in a game increases your discount at a real-world store,
            or enjoying a cup of coffee at a café boosts your online character's
            experience points. How exciting would that be?
            <br />
            <br />
            Partner with Map3 Protocol, where we expand the lore of the online
            world into reality.
          </div>
          <div className="w-full flex justify-center">
            <div className="w-[90%] h-[337.66px] top-[994px] absolute">
              <img
                className="w-full h-[337.66px] left-0 top-0 absolute object-cover"
                src={HMPBenefitImage}
                alt="HMP Benefit"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer scrollToTop={goToHome} />
    </>
  );
}

export default Story;
