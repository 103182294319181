import React from 'react';

const LetterLogo = () => (
    <svg width="126" height="18" viewBox="0 0 126 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M3.49901 4.20104V5.39668C4.10313 4.34478 4.91522 3.87109 6.14327 3.87109C7.37131 3.87109 8.30886 4.42645 8.76442 5.45549C9.40816 4.36438 10.3028 3.87109 11.6794 3.87109C12.6565 3.87109 13.4686 4.18144 14.01 4.75639C14.5943 5.39342 14.7792 6.05331 14.7792 7.49723V13.7368H12.1349V7.99051C12.1349 6.6936 11.6563 6.07618 10.656 6.07618C9.65575 6.07618 9.11435 6.77527 9.11435 7.99051V13.7368H6.47009V8.07218C6.47009 6.75567 5.9716 6.07291 5.01426 6.07291C4.05691 6.07291 3.4957 6.7524 3.4957 8.07218V13.7368H0.85144V4.20104H3.4957H3.49901Z" fill="white"/>
<path id="Vector_2" d="M24.7257 4.20104H27.37V13.7368H24.7257V12.4203C23.8311 13.5539 22.7912 14.0667 21.375 14.0667C18.668 14.0667 16.7335 11.9858 16.7335 9.08162C16.7335 6.17745 18.7737 3.87109 21.583 3.87109C22.8936 3.87109 23.7882 4.32518 24.7257 5.41628V4.20104ZM19.566 9.04242C19.566 10.7117 20.6686 11.9662 22.1046 11.9662C23.5406 11.9662 24.7686 10.6072 24.7686 8.95748C24.7686 7.30775 23.6859 6.07291 22.1244 6.07291C20.5629 6.07291 19.566 7.28815 19.566 9.03915V9.04242Z" fill="white"/>
<path id="Vector_3" d="M32.6156 4.20104V5.37382C33.4046 4.32191 34.3421 3.87109 35.7781 3.87109C36.7982 3.87109 37.6499 4.11937 38.462 4.65512C39.9178 5.64496 40.6474 7.08561 40.6474 8.99995C40.6474 12.0479 38.7327 14.0667 35.8409 14.0667C34.4477 14.0667 33.4277 13.6355 32.6156 12.7078V17.9999H29.9713V4.20104H32.6156ZM32.6156 9.08162C32.6156 10.813 33.6356 11.9662 35.174 11.9662C36.7124 11.9662 37.8183 10.7509 37.8183 9.06202C37.8183 7.37309 36.7553 6.07618 35.2598 6.07618C33.7644 6.07618 32.6156 7.31102 32.6156 9.08489V9.08162Z" fill="white"/>
<path id="Vector_4" d="M42.5424 2.34894V0.0817871H50.9934L47.1442 5.21064C49.3924 5.39685 50.8482 7.02371 50.8482 9.34966C50.8482 12.0284 48.8906 14.0048 46.2265 14.0048C44.6848 14.0048 43.3347 13.3449 42.5622 12.1918C42.1462 11.5743 41.9779 11.0386 41.8524 10.0096L44.2458 9.92789C44.4108 10.9994 45.1404 11.6364 46.1605 11.6364C47.3456 11.6364 48.2832 10.7315 48.2832 9.53587C48.2832 8.34023 47.3885 7.43533 46.1407 7.43533C45.662 7.43533 45.2659 7.53986 44.8697 7.78487L43.371 6.52716L46.5335 2.34567H42.5391L42.5424 2.34894Z" fill="white"/>
<path id="Vector_5" d="M55.6976 4.20104V5.37382C56.4866 4.32191 57.4241 3.87109 58.8602 3.87109C59.8802 3.87109 60.7319 4.11937 61.544 4.65512C62.9999 5.64496 63.7294 7.08561 63.7294 8.99995C63.7294 12.0479 61.8147 14.0667 58.9229 14.0667C57.5298 14.0667 56.5097 13.6355 55.6976 12.7078V17.9999H53.0533V4.20104H55.6976ZM55.6976 9.08162C55.6976 10.813 56.7177 11.9662 58.256 11.9662C59.7944 11.9662 60.9003 10.7509 60.9003 9.06202C60.9003 7.37309 59.8373 6.07618 58.3419 6.07618C56.8464 6.07618 55.6976 7.31102 55.6976 9.08489V9.08162Z" fill="white"/>
<path id="Vector_6" d="M68.371 4.20104V5.60249C68.6416 4.9034 68.8298 4.61265 69.226 4.32518C69.6419 4.0377 70.2031 3.87109 70.7874 3.87109C70.8502 3.87109 70.9525 3.87109 71.0977 3.89069V6.56946C70.8898 6.54986 70.6818 6.52699 70.5365 6.52699C69.807 6.52699 69.1401 6.85694 68.7671 7.39269C68.4535 7.82391 68.371 8.23879 68.371 9.14369V13.7368H65.7267V4.20104H68.371Z" fill="white"/>
<path id="Vector_7" d="M82.5232 9.06217C82.5232 11.8618 80.2124 14.0669 77.2578 14.0669C74.3032 14.0669 72.032 11.9467 72.032 8.9805C72.032 6.01426 74.1745 3.87451 77.2974 3.87451C80.4203 3.87451 82.5199 6.05673 82.5199 9.06544L82.5232 9.06217ZM74.6994 8.9805C74.6994 10.627 75.7392 11.7409 77.2809 11.7409C78.8226 11.7409 79.8624 10.6074 79.8624 9.02297C79.8624 7.29157 78.8853 6.22333 77.304 6.22333C75.7227 6.22333 74.7027 7.31444 74.7027 8.98377L74.6994 8.9805Z" fill="white"/>
<path id="Vector_8" d="M87.2274 10.9372C87.2274 11.3913 87.3925 11.5154 87.9768 11.5546H88.4356V13.7369H86.3328C85.042 13.6944 84.5633 13.1815 84.5831 11.9238V6.36371H83.4805V4.15863H84.5831V1.604H87.2274V4.15863H88.4356V6.36371H87.2274V10.9372Z" fill="white"/>
<path id="Vector_9" d="M100.132 9.06217C100.132 11.8618 97.8209 14.0669 94.8664 14.0669C91.9118 14.0669 89.6406 11.9467 89.6406 8.9805C89.6406 6.01426 91.783 3.87451 94.906 3.87451C98.0289 3.87451 100.128 6.05673 100.128 9.06544L100.132 9.06217ZM92.3046 8.9805C92.3046 10.627 93.3445 11.7409 94.8862 11.7409C96.4278 11.7409 97.4677 10.6074 97.4677 9.02297C97.4677 7.29157 96.4906 6.22333 94.9093 6.22333C93.328 6.22333 92.3079 7.31444 92.3079 8.98377L92.3046 8.9805Z" fill="white"/>
<path id="Vector_10" d="M110.266 12.6458C109.164 13.6357 108.018 14.0669 106.563 14.0669C103.628 14.0669 101.462 11.9239 101.462 9.02297C101.462 6.12206 103.585 3.87451 106.582 3.87451C108.018 3.87451 109.203 4.3678 110.432 5.4589L108.662 7.04656C108.078 6.46834 107.454 6.20046 106.685 6.20046C105.229 6.20046 104.146 7.37324 104.146 8.93803C104.146 10.5028 105.249 11.7377 106.79 11.7377C107.477 11.7377 107.975 11.5319 108.665 10.934L110.266 12.6426V12.6458Z" fill="white"/>
<path id="Vector_11" d="M121.401 9.06217C121.401 11.8618 119.091 14.0669 116.136 14.0669C113.181 14.0669 110.91 11.9467 110.91 8.9805C110.91 6.01426 113.053 3.87451 116.176 3.87451C119.299 3.87451 121.398 6.05673 121.398 9.06544L121.401 9.06217ZM113.574 8.9805C113.574 10.627 114.614 11.7409 116.156 11.7409C117.697 11.7409 118.737 10.6074 118.737 9.02297C118.737 7.29157 117.76 6.22333 116.179 6.22333C114.598 6.22333 113.578 7.31444 113.578 8.98377L113.574 8.9805Z" fill="white"/>
<path id="Vector_12" d="M123.359 13.7368V0H126.003V13.7368H123.359Z" fill="white"/>
</g>
</svg>

);

export default LetterLogo;