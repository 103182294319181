import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import HyunYuImage from "../../images/HyunYu.png";
import EricChoiImage from "../../images/EricChoi.png";
import BongakGuImage from "../../images/BongakGu.png";
import DonghyunKimImage from "../../images/DonghyunKim.png";
import DannyKimImage from "../../images/DannyKim.png";
import { useNavigate } from "react-router-dom";

function Team() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const goToHome = () => {
    navigate("/");
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <>
      <div className="relative">
        <Header isMenuVisible={isMenuVisible} toggleMenu={toggleMenu} />

        <div className="w-full h-[2460px] relative">
          <div className="w-[360px] h-[2460px] left-0 top-0 absolute bg-black" />

          <div className="w-[90%] left-[22px] top-[137px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-1">
            We are creating an offline map(Map3) that will work with the
            upcoming metaverse, Web3 ecosystem. We’re making a team of the best
            people to carry out the great mission.
          </div>
          <div className="left-1/2 transform -translate-x-1/2 top-[69px] absolute text-center text-white text-[28px] font-bold font-['PT Mono'] leading-loose">
            Our Team
          </div>
          <div className="w-[90%] left-[22px] top-[394px] absolute">
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Experience
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  display: "block",
                  lineHeight: "1.2rem",
                }}
              >
                Successfully launched and exited multiple F&B brands, including
                Italian restaurants, pizzerias, churros stores, cafes, bakeries,
                donut shops, and Korean-style bars. Former software engineer
                with expertise in big data, recommendation algorithms, Ruby, and
                Android development.
                <br />
              </span>
            </div>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "normal",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Vision
            </span>
            <br />
            <div style={{ marginLeft: "8px" }} className="w-[90%]">
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  lineHeight: "1.2rem",
                  display: "block",
                }}
              >
                "With a deep understanding of F&B operations and expertise in
                Web3 technology, my goal is to create a seamless connection
                between physical spaces and digital experiences."
              </span>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[827px] absolute">
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Experience
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  display: "block",
                  lineHeight: "1.2rem",
                }}
              >
                Over 5 years of experience in software development and 3 years
                in blockchain development. Holds a Master of Science in Data
                Science from Harvard.
                <br />
              </span>
            </div>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "normal",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Vision
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  lineHeight: "1.2rem",
                  display: "block",
                }}
              >
                "Data is the key to bridging Web3 and the physical world. With
                my background in data analysis and blockchain, I aim to build a
                platform where users can experience real value and provide
                meaningful data that strengthens the Web3 ecosystem."
              </span>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[1257px] absolute">
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Experience
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  display: "block",
                  lineHeight: "1.2rem",
                }}
              >
                Over 5 years of experience in brand design and illustration. Has
                collaborated with major brands such as SM Entertainment and YBM.
                <br />
              </span>
            </div>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "normal",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Vision
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  lineHeight: "1.2rem",
                  display: "block",
                }}
              >
                “I strive to create a new brand experience on the Web3 platform,
                one that resonates with users and fosters a sense of
                connection."
              </span>
            </div>
          </div>
          <div className="w-[318px] left-[22px] top-[1641px] absolute">
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Experience
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  display: "block",
                  lineHeight: "1.2rem",
                }}
              >
                Over 10 years of experience in retail operations, managing large
                supermarkets, restaurants, and pop-up stores.
                <br />
              </span>
            </div>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Vision
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  lineHeight: "1.2rem",
                  display: "block",
                }}
              >
                "By integrating Web3 technology, I aim to build a global network
                of partner stores, making the platform easily accessible and
                engaging for users around the world."
              </span>
            </div>
          </div>
          <div className="w-[318px] left-[22px] top-[2028px] absolute">
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Experience
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  display: "block",
                  lineHeight: "1.2rem",
                }}
              >
                Board Member at Digital Therapeutics Alliance, Corporate
                Development Analyst at Pear Therapeutics, and Operations at
                PillPack (acquired by Amazon). Doctor of Pharmacy from
                Northeastern University. Despite his young age, he has exit
                experience in the U.S. and a strong network of influential and
                capable individuals, connecting our company with strategic
                partners.
                <br />
              </span>
            </div>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              <br />
            </span>
            <span
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "PT Sans",
                lineHeight: "none",
              }}
            >
              • Vision
            </span>
            <br />
            <div style={{ marginLeft: "8px" }}>
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontFamily: "PT Sans",
                  lineHeight: "1.2rem",
                  display: "block",
                }}
              >
                "With my exit experience in the U.S. and extensive network, I am
                dedicated to connecting our company with world-class talent and
                establishing strategic partnerships that accelerate our
                success."
              </span>
            </div>
          </div>
          <div className="left-[143px] top-[328px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Hyun Yu
          </div>
          <div className="left-[143px] top-[761px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Eric Choi
          </div>
          <div className="left-[143px] top-[1191px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Bongak Gu
          </div>
          <div className="left-[143px] top-[1574px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Donghyun Kim
          </div>
          <div className="left-[143px] top-[1961px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Danny Kim
          </div>
          <div className="left-[143px] top-[362px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Founder & CEO
          </div>
          <div className="left-[143px] top-[795px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Co-founder & CTO
          </div>
          <div className="left-[143px] top-[1225px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Branding Director
          </div>
          <div className="left-[143px] top-[1608px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Opreation Manager
          </div>
          <div className="left-[143px] top-[1995px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Advisor
          </div>
          <img
            className="w-[98px] h-[136px] left-[22px] top-[242px] absolute rounded-[20px]"
            src={HyunYuImage}
            alt="Hyun Yu"
          />
          <img
            className="w-[98px] h-[136px] left-[22px] top-[675px] absolute rounded-[20px]"
            src={EricChoiImage}
            alt="Eric Choi"
          />
          <div className="w-[98px] h-[135.22px] left-[22px] top-[1106px] absolute">
            <div className="w-[98px] h-[43.19px] left-0 top-0 absolute bg-gradient-to-r from-[#ececec] to-[#e3e3e3] rounded-[20px]" />
            <img
              className="w-[98px] h-[135.22px] left-0 top-0 absolute rounded-[20px]"
              src={BongakGuImage}
              alt="Bongak Gu"
            />
          </div>
          <img
            className="w-[98px] h-[136px] left-[22px] top-[1489px] absolute rounded-[20px]"
            src={DonghyunKimImage}
            alt="Donghyun Kim"
          />
          <img
            className="w-[98px] h-[135px] left-[22px] top-[1877px] absolute rounded-[20px]"
            src={DannyKimImage}
            alt="Danny Kim"
          />
          <div className="w-[360px] h-[47px] left-0 top-0 absolute">
            <div className="w-[360px] h-[47px] left-0 top-0 absolute bg-black" />
            <div className="w-11 h-8 px-2.5 py-[18px] left-[316px] top-0 absolute flex-col justify-start items-start gap-2.5 inline-flex" />
            <div className="w-[100px] h-3 left-[20px] top-[17px] absolute justify-center items-start gap-[4.44px] inline-flex">
              <div className="w-[83.43px] h-3 relative"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer scrollToTop={goToHome} />
    </>
  );
}

export default Team;
