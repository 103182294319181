import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import PartnershipPageBackgroundImage from "../../images/PartnershipPageBackground.png";
import GeumYaImage from "../../images/GeumYa.png";
import PartnershipPageImage2 from "../../images/PartnershipPageImage2.png";

function Partnership() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const goToHome = () => {
    navigate("/");
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <>
      <div className="w-full min-h-screen bg-black relative overflow-x-hidden">
        <Header
          toggleMenu={toggleMenu}
          scrollToTop={goToHome}
          isMenuVisible={isMenuVisible}
        />
        <div className="w-full h-[1171px] relative">
          <div className="w-full h-[1171px] left-0 top-0 absolute bg-black" />
          <div className="w-full left-0 top-0 absolute flex-col justify-center items-center">
            <div
              className="w-full h-[378px] relative"
              style={{
                backgroundImage: `linear-gradient(to bottom, 
                rgba(0,0,0,0) 80%, 
                rgba(0,0,0,1) 100%
              ), url(${GeumYaImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: "0.4",
              }}
            />
          </div>
          <div className="left-[16px] top-[485px] absolute text-white text-2xl font-bold font-['PT Mono'] leading-normal">
            Partnership F&B Stores
          </div>
          <div className="w-[90%] left-[16px] top-[533px] absolute text-white/70 text-xs font-normal font-['PT Sans'] leading-1">
            Introducing various f&B spaces around the world that have joined the
            ecosystem of HMP. NFT holders from the HMP ecosystem can receive
            special benefits at all affiliates.
          </div>
          <div className="w-screen px-4 h-[284.09px] top-[601px] absolute flex-col justify-center items-center gap-2.5 inline-flex">
            <img
              className="w-full h-[264px] object-cover"
              src={PartnershipPageImage2}
              alt="Partnership Page"
            />
          </div>
          <div className="right-[24px] top-[260px] absolute text-right text-white text-2xl font-bold font-['PT Sans'] leading-7">
            A new revolution in F&B
            <br />
            is taking place here.
          </div>

          <div className="left-[71px] top-[906px] absolute flex-col justify-start items-start gap-2.5 inline-flex">
            <a
              href="https://galvanized-radon-a17.notion.site/5e856988fc3448fd9ace808eca571694?v=21923ed00762415cb4fedb39e08a3c03"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-block"
            >
              <div className="px-5 py-2.5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex whitespace-nowrap">
                <div className="text-white text-xs font-normal font-['PT Sans'] leading-3">
                  View more Partnership Restaurants
                </div>
              </div>
            </a>
          </div>

          <div className="left-[75px] top-[959px] absolute flex-col justify-start items-start gap-2.5 inline-flex">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScAdcz8GOOk35GUfDMN2nZTLu5ulzOQpnFNb_pEoSnfd8Y3nQ/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-block"
            >
              <div className="self-stretch px-5 py-2.5 rounded-[50px] border border-[#ef5230] justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-xs font-normal font-['PT Sans'] leading-3">
                  Contact for HMP sites registration
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer scrollToTop={goToHome} />
    </>
  );
}

export default Partnership;
