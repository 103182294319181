import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Story from "./components/webview/Story";
import Team from "./components/webview/Team";
import Partnership from "./components/webview/Partnership";
import MobileviewHome from "./components/mobileview/Home";
import WebviewHome from "./components/webview/Home";
import MobileviewStory from "./components/mobileview/Story";
import WebviewStory from "./components/webview/Story";
import MobileviewTeam from "./components/mobileview/Team";
import WebviewTeam from "./components/webview/Team";
import MobileviewPartnership from "./components/mobileview/Partnership";
import WebviewPartnership from "./components/webview/Partnership";
import useWindowSize from "./hooks/useWindowSize";
import "./App.css";

function App() {
  const { width } = useWindowSize();
  const isMobile = width <= 768; // You can adjust this breakpoint

  return (
    <Router>
      <div className="App app-container">
        <Routes>
          <Route
            path="/"
            element={isMobile ? <MobileviewHome /> : <WebviewHome />}
          />
          <Route
            path="/story"
            element={isMobile ? <MobileviewStory /> : <WebviewStory />}
          />
          <Route
            path="/team"
            element={isMobile ? <MobileviewTeam /> : <WebviewTeam />}
          />
          <Route
            path="/partnership"
            element={
              isMobile ? <MobileviewPartnership /> : <WebviewPartnership />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
