import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import HMPEuljiroImage from "../../images/HMPEuljiro.png";
import HMPLetterLogoImage from "../../images/HMPLetterLogo.png";
import HMPBenefitImage from "../../images/HMPBenefit.png";

function Story() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Force scroll to top when component mounts
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const goToHome = () => {
    navigate("/");
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <div className="w-full min-h-screen bg-black relative overflow-x-hidden">
      <Header
        toggleMenu={toggleMenu}
        scrollToTop={goToHome}
        isMenuVisible={isMenuVisible}
      />
      <div className="relative pb-20">
        <div className="left-[200px] top-[309px] absolute text-white text-base font-normal font-['PT Sans'] leading-normal">
          by Hyun Yu (Founder&CEO)
        </div>
        <img
          className="w-[512px] h-[512px] left-[728px] top-[162px] absolute"
          src={HMPEuljiroImage}
          alt="HMP Euljiro"
        />
        <div className="w-[155px] h-[93px] left-[919px] top-[197px] absolute shadow">
          <img
            className="w-[152.12px] h-[84.44px] left-[1.44px] top-[3.60px] absolute"
            src={HMPLetterLogoImage}
            alt="HMP Letter Logo"
          />
        </div>
        <div className="w-[710px] px-[200px] py-2.5 left-0 top-[239px] absolute">
          <div className="text-white text-4xl font-bold font-['PT Mono'] leading-[60px]">
            Ixplorer's Story
          </div>
        </div>
        <div className="w-[475px] left-[200px] top-[389px] absolute text-white/70 text-base font-normal font-['PT Sans'] leading-normal">
          I have successfully operated a variety of brands, including churros,
          Italian restaurants, pizza shops, bakeries, donut shops, and pubs.
          However, I found it challenging to scale the business further without
          relying on franchising. This led me to explore new and innovative ways
          to make the most out of these offline stores.
          <br />
          <br />
          One day, I noticed that all the customers in my café were playing
          Pokémon Go. At first, I thought they were simply having fun, but I
          soon discovered that my café was set as an in-game item shop
          (PokéStop) in Pokémon Go. As a result, a huge number of people started
          visiting, and sales skyrocketed. But what excited me even more was
          realizing the potential of linking games with offline stores.
        </div>
        <div className="w-[475px] left-[730px] top-[924px] absolute text-white/70 text-base font-normal font-['PT Sans'] leading-normal">
          To bring this vision to life, I saw Web3 technology, with its high
          degree of connectivity, as the answer. Imagine a system where leveling
          up in a game increases your discount at a real-world store, or
          enjoying a cup of coffee at a café boosts your online character's
          experience points. How exciting would that be?
          <br />
          <br />
          Partner with Map3 Protocol, where we expand the lore of the online
          world into reality.
        </div>
        <div className="w-[84.39px] h-[51px] left-[258px] top-[209px] absolute">
          <div className="left-[4.35px] top-0 absolute origin-top-left rotate-[12.57deg] text-[#ef5230] text-xs font-normal font-['PT Sans'] leading-tight">
            CEO's Nickname
          </div>
        </div>

        <img
          className="w-[447px] h-[472px] left-[200px] top-[924px] absolute"
          src={HMPBenefitImage}
          alt="HMP Benefit"
        />
      </div>
      <div
        className="relative pointer-events-auto"
        style={{ transform: "translateY(-4400px)" }}
      >
        <Footer scrollToTop={goToHome} />
      </div>
    </div>
  );
}

export default Story;
